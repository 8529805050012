<template>
    <CheckOutCard class="card" v-if="order.mealKit">
        <!-- Header -->
        <div class="inner-item">
            <div class="header">
                <h4 @click="toMealKitPage(order.mealKit)">
                    {{ getMealKitTitle(order.mealKit.name, order.amount) }}
                </h4>
                <div class="tag__wrapper" v-if="!order.mealKit.isUnique">
                    <!-- Meals amount -->
                    <CRTag class="tag">{{ order.combination.combination.length }} {{ $t('common.meals') }}</CRTag>

                    <!-- Menu type -->
                    <CRTag class="tag">{{ $t(`common.${order.menuType.type}`) }}</CRTag>

                    <!-- Calories -->
                    <CRTag class="tag">{{ order.calorie.amount }}kcal</CRTag>

                    <!-- Period -->
                    <CRTag class="tag">{{ $filters.getTranslationByKey(order.period.name) }}</CRTag>

                    <!-- Combination -->
                    <CRTag class="tag">{{ $filters.getTranslationByKey(order.combination.name) }}</CRTag>

                    <!-- Allergens -->
                    <CRTag v-for="allergen in order.allergens" :key="allergen.id" class="tag">
                        {{ renderAllergenName(false, allergen, order.additionalAllergen) }}
                    </CRTag>

                    <!-- Additions -->
                    <CRTag v-for="addition in order.additions" :key="addition.id" class="tag">
                        {{ $filters.getTranslationByKey(addition.name) }}
                    </CRTag>
                </div>

                <!-- Update Request -->
                <div class="f-column-align-start">
                    <CRTag v-if="order.updateRequest?.data.combination" class="tag mb-8" type="special">
                        {{ $filters.getTranslationByKey(order.updateRequest.data.combination.name) }}
                        {{ order.updateRequest.changesAppliedDate }}
                    </CRTag>
                    <CRTag v-if="order.updateRequest?.data.menuType" class="tag mb-8" type="special">
                        {{ $t(`common.${order.updateRequest.data.menuType.type}`) }}
                        {{ order.updateRequest.changesAppliedDate }}
                    </CRTag>
                </div>

                <p v-if="!isAdmin" class="text-danger">
                    <template v-if="isWaitingAccountPayment">
                        {{ $t('orderManagement.pleaseMakePaymentWithin6h') }}
                    </template>
                </p>

                <div class="status__wrapper">
                    <template v-if="isCanceledStatus">
                        <h1 class="danger">
                            {{ $t('kitManagement.orderCanceled') }}
                        </h1>
                        <p class="subtitle">
                            {{ $t(`kitManagement.has_${order.status}`) }}
                        </p>
                    </template>
                </div>
            </div>
        </div>

        <!-- Delivery date -->
        <div class="inner-item">
            <div class="f-align-center">
                <!-- Delivery start date -->
                <OMTitleSection
                    class="f-column-align-start mr-20"
                    reverse
                    :title="
                        !order.mealKit.isUnique ? $t('orderManagement.deliveryStartDate') : $t('common.deliveryDate')
                    "
                    :text="order.deliveryStartDate"
                />
                <!-- Delivery end date -->
                <OMTitleSection
                    v-if="!order.mealKit.isUnique"
                    class="f-column-align-start"
                    reverse
                    :title="$t('orderManagement.deliveryEndDate')"
                    :text="order.deliveryEndDate"
                />
            </div>
        </div>

        <!-- Delivery time -->
        <div class="inner-item">
            <OMTitleSection
                class="f-column-align-start"
                reverse
                :title="$t('orderManagement.deliveryTimeInHours')"
                :text="getLabelForDeliveryTime(order.deliveryTime)"
            />
        </div>

        <!-- Prices -->
        <div class="inner-item">
            <div class="f-align-start-justify-between">
                <div class="payment-text__wrapper">
                    <p v-if="isWaitingPaymentStatus && !isAdmin" class="text-danger">
                        {{ $t(`orderManagement.${checkOut.payment.paymentableType}_payment_description`) }}
                    </p>
                </div>
                <!-- Price -->
                <OMTitleSection class="f-column-align-end">
                    <h2>€{{ order.prices.total_price }}</h2>
                    <h6 v-if="!order.mealKit.isUnique" class="text-end">
                        {{ $filters.getTranslationByKey(order.period.name) }}
                    </h6>
                </OMTitleSection>
            </div>

            <CRButton v-if="!isAdmin && isNotUniqueKit" class="w-full mt-12" @click="$emit('reorderRegularKit', order)">
                {{ $t('buttons.reorder') }}
            </CRButton>
            <CRButton
                v-if="isChangeOrderBtnVisible"
                class="w-full mt-12"
                pattern="outline"
                @click="$emit('openChangeOrderModal', order)"
            >
                {{ $t('buttons.changeOrder') }}
            </CRButton>
            <div v-if="!isAdmin && isCancellationBtnVisibleForUser" class="f-align-start-justify-end mt-12">
                <CRButton pattern="thin" @click="isMoreSettingsShown = !isMoreSettingsShown">
                    {{ $t('buttons.moreSettings') }}
                </CRButton>
            </div>
        </div>

        <!-- Cancellation -->
        <div v-if="isPaidStatus && isAdmin" class="inner-item">
            <CRButton class="w-full" color="danger" @click="updateOrder(OrderStatusEnum.CANCELED_BY_ADMIN)">
                {{ $t('buttons.cancelOrder') }}
            </CRButton>
        </div>
        <div v-if="isCancellationBtnVisibleForUser && isMoreSettingsShown" class="inner-item">
            <CRButton class="w-full mb-12" @click="updateOrder(OrderStatusEnum.CANCELED_BY_USER)" color="danger">
                {{ $t('buttons.cancelOrder') }}
            </CRButton>
            <p class="subtitle mb-20">
                {{
                    $t('kitManagement.regularOrderCancellationSubtitle1', {
                        hours: kitOrderSettings?.cancellationTermHours,
                    })
                }}
            </p>
            <p class="subtitle">
                {{ $t('kitManagement.regularOrderCancellationSubtitle2') }}
            </p>
        </div>

        <div v-if="isCanceledByUserStatus" class="inner-item">
            <div class="f-align-end-justify-between">
                <div class="payment-text__wrapper">
                    <p class="subtitle">
                        <template v-if="isPendingMoneyBackStatus">
                            {{ $t('kitManagement.amountToBeReturned') }}
                        </template>
                        <template v-if="isPaidMoneyBackStatus">
                            {{ $t('kitManagement.amountReturned') }}
                        </template>
                    </p>
                </div>
                <!-- Price -->
                <OMTitleSection class="f-column-align-end">
                    <h2>€{{ order.moneyBack.amount }}</h2>
                </OMTitleSection>
            </div>
            <CRButton
                v-if="isAdmin && isPendingMoneyBackStatus"
                class="w-full mt-12"
                @click="confirmReturnAmount(MoneyBackStatusEnum.PAID)"
            >
                {{ $t('buttons.confirmReturnedAmount') }}
            </CRButton>
        </div>
    </CheckOutCard>
</template>

<script>
    import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
    import { renderAllergenName } from '@/helpers/AllergenHelper';
    import CheckOutCard from '@/components/OrderManagement/CheckOutCard.vue';
    import OMTitleSection from '@/components/OrderManagement/OMTitleSection.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { CheckOutStatusEnum, PaymentTypeEnum, OrderStatusEnum, MoneyBackStatusEnum } from '@/utils/enums';
    import { mapGetters } from 'vuex';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'RegularOrderKitCard',
        components: { CheckOutCard, CRTag, OMTitleSection, CRButton },
        props: {
            order: {
                type: Object,
                default: () => {},
            },
            checkOut: {
                type: Object,
                default: () => {},
            },
            kitOrderSettings: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                renderAllergenName,
                getLabelForDeliveryTime,
                OrderStatusEnum,
                MoneyBackStatusEnum,

                isMoreSettingsShown: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isAdmin']),

            isChangeOrderBtnVisible() {
                return (
                    !this.isAdmin &&
                    !this.isCanceledStatus &&
                    !this.isCompletedStatus &&
                    !this.isNotPaidStatus &&
                    !this.kitOrderSettings?.isUserChangesDisabled
                );
            },

            isCancellationBtnVisibleForUser() {
                return (
                    (this.isPaidStatus || this.isWaitingCashPayment) &&
                    !this.isAdmin &&
                    !this.kitOrderSettings?.isUserCancellationDisabled
                );
            },

            isNotUniqueKit() {
                return !this.order.mealKit.isUnique;
            },

            isWaitingPaymentStatus() {
                return this.checkOut.status === CheckOutStatusEnum.WAITING_PAYMENT;
            },

            isPaidStatus() {
                return this.order.status === OrderStatusEnum.PAID;
            },

            isNotPaidStatus() {
                return this.order.status === OrderStatusEnum.NOT_PAID;
            },

            isCanceledStatus() {
                return this.isCanceledByUserStatus || this.isCanceledByAdminStatus;
            },

            isCanceledByUserStatus() {
                return this.order.status === OrderStatusEnum.CANCELED_BY_USER;
            },

            isCanceledByAdminStatus() {
                return this.order.status === OrderStatusEnum.CANCELED_BY_ADMIN;
            },

            isCompletedStatus() {
                return this.order.status === OrderStatusEnum.COMPLETED;
            },

            isPendingMoneyBackStatus() {
                return this.order.moneyBack.status === MoneyBackStatusEnum.PENDING;
            },

            isPaidMoneyBackStatus() {
                return this.order.moneyBack.status === MoneyBackStatusEnum.PAID;
            },

            isWaitingAccountPayment() {
                return (
                    this.isWaitingPaymentStatus &&
                    this.checkOut.payment.paymentableType === PaymentTypeEnum.BANK_ACCOUNT
                );
            },

            isWaitingCashPayment() {
                return this.isWaitingPaymentStatus && this.checkOut.payment.paymentableType === PaymentTypeEnum.CASH;
            },
        },
        methods: {
            async toMealKitPage(mealKit) {
                this.$router.push({
                    name: 'mealKit',
                    params: { meal_kit_id: mealKit.id },
                });

                fbq('track', ConversionEventNameEnum.VIEW_CONTENT, {
                    content_ids: [mealKit.id],
                    content_name: mealKit.name,
                    content_type: 'product'
                });
            },

            getMealKitTitle(mealKitName, amount) {
                if (amount > 1) {
                    return `${amount} x ${this.$filters.getTranslationByKey(mealKitName)}`;
                } else {
                    return this.$filters.getTranslationByKey(mealKitName);
                }
            },

            updateOrder(status) {
                this.$emit('updateOrder', { status, order: this.order });
            },

            confirmReturnAmount(status) {
                this.$emit('confirmReturnAmount', { status, order: this.order });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        width: 100%;

        .inner-item {
            padding: 10px 0;

            border-bottom: 1px solid $grey-line;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        .header {
            h4 {
                cursor: pointer;
                margin-bottom: 12px;
                text-decoration: underline;
            }

            .tag__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                .tag {
                    margin-right: 8px;
                    margin-bottom: 10px;
                }
            }
        }

        .status__wrapper {
            h1 {
                font-size: $font-30;
                line-height: 120%;
            }

            .danger {
                color: $danger;
                max-width: 80%;
            }
        }

        .subtitle {
            font-size: $font-14;
            font-style: italic;
        }

        .text-danger {
            font-size: $font-14;
            color: $danger;
            font-style: italic;
        }

        .payment-text__wrapper {
            max-width: 49%;
            margin-right: 1%;
        }
    }
</style>
