<template>
    <CheckOutCard class="card">
        <!-- Header -->
        <div class="inner-item">
            <div class="header">
                <h4 @click="$emit('openIndividualOrderModal', order)" class="title">
                    {{ $t('navbar.individualOrder') }}
                </h4>

                <p v-if="!isAdmin" class="text-danger">
                    <template v-if="isOrderRequestStatus">
                        {{ $t('orderManagement.responseTakesUp24h') }}
                    </template>
                    <template v-if="isOrderApprovedStatus">
                        {{ $t('orderManagement.pleaseMakePaymentWithin6h') }}
                    </template>
                    <template v-if="isWaitingAccountPayment">
                        {{ $t('orderManagement.pleaseMakePaymentWithin6h') }}
                    </template>
                </p>

                <div v-if="isDeclinedStatus" class="status__wrapper">
                    <h1 class="danger">
                        {{ $t('kitManagement.orderCanceled') }}
                    </h1>
                    <p class="subtitle">
                        {{ $t('kitManagement.has_canceled_by_admin') }}
                    </p>
                </div>
                <div v-if="isNotPaidStatus" class="status__wrapper">
                    <h1 class="danger">
                        {{ $t('kitManagement.orderCanceled') }}
                    </h1>
                    <p class="subtitle">
                        {{ $t('orderManagement.orderCanceled') }}
                    </p>
                </div>
                <div v-if="isCancellationRequestStatus" class="status__wrapper">
                    <h1 class="danger">
                        {{ $t('kitManagement.cancellationRequest') }}
                    </h1>
                    <p v-if="!isAdmin" class="subtitle text-danger">
                        {{ $t('kitManagement.cancellationRequestSubtitle') }}
                    </p>
                </div>

                <p class="payment-text" v-if="isPaidStatus">
                    {{ $t(`orderManagement.through_${checkOut.payment.paymentableType}_payment`) }}
                </p>

                <!-- Compleat order section -->
                <div v-if="isPaidStatus && isAdmin" class="order-request-section">
                    <CRButton @click="openConfirmationModal(CheckOutStatusEnum.COMPLETED)" class="btn">
                        {{ $t('buttons.completed') }}
                    </CRButton>
                </div>

                <!-- Enter price section -->
                <div v-if="isOrderRequestStatus && isAdmin">
                    <slot name="enter-price-section" />
                </div>
            </div>
        </div>

        <!-- Time of order approval -->
        <div class="inner-item" v-if="isTimeApprovalSectionVisible">
            <OMTitleSection
                class="f-column-align-start"
                reverse
                :title="$t('orderManagement.timeOfOrderApproval')"
                :text="order.approvedAt"
            />
        </div>

        <!-- Delivery dates -->
        <div class="inner-item">
            <div class="f-align-center">
                <!-- Delivery start date -->
                <OMTitleSection
                    class="f-column-align-start mr-20"
                    reverse
                    :title="$t('orderManagement.deliveryStartDate')"
                    :text="deliveryStartDate"
                />
                <!-- Delivery end date -->
                <OMTitleSection
                    class="f-column-align-start"
                    reverse
                    :title="$t('orderManagement.deliveryEndDate')"
                    :text="deliveryEndDate"
                />
            </div>
        </div>

        <!-- Delivery time -->
        <div class="inner-item">
            <OMTitleSection
                class="f-column-align-start"
                reverse
                :title="$t('orderManagement.deliveryTimeInHours')"
                :text="getLabelForDeliveryTime(order.deliveryTime)"
            />
        </div>

        <!-- Price -->
        <div class="inner-item price__wrapper" v-if="checkOut.prices">
            <div class="payment-text__wrapper">
                <p v-if="isWaitingPaymentStatus && !isAdmin" class="text-danger">
                    {{ $t(`orderManagement.${checkOut.payment.paymentableType}_payment_description`) }}
                </p>
            </div>
            <OMTitleSection class="price-item f-column-align-end">
                <h2>€{{ checkOut.prices.total_price }}</h2>
                <h6>
                    {{ $filters.getTranslationByKey(order.period.name) }}
                </h6>
            </OMTitleSection>

            <CRButton v-if="!isAdmin && isOrderApprovedStatus" class="payment-btn" @click="makePayment">
                {{ $t('buttons.makePayment') }}
            </CRButton>
            <CRButton
                v-if="isAdmin && isWaitingPaymentStatus"
                class="payment-btn"
                @click="openConfirmationModal(CheckOutStatusEnum.PAID)"
            >
                {{ $t('buttons.approvePayment') }}
            </CRButton>
            <CRButton
                v-if="isAdmin && (isWaitingPaymentStatus || isOrderApprovedStatus)"
                class="payment-btn"
                color="danger"
                @click="openConfirmationModal(CheckOutStatusEnum.NOT_PAID)"
            >
                {{ $t('buttons.paymentNotMade') }}
            </CRButton>
        </div>

        <!-- Reorder -->
        <div v-if="!isAdmin" class="inner-item">
            <CRButton class="w-full" @click="$emit('reorderIndividualOrder', order)">
                {{ $t('buttons.reorder') }}
            </CRButton>
            <CRButton
                v-if="isChangeOrderBtnVisible"
                class="w-full mt-12"
                pattern="outline"
                @click="$emit('openChangeOrderModal', order)"
            >
                {{ $t('buttons.changeOrder') }}
            </CRButton>
            <div v-if="!isAdmin && isCancellationRequestBtnVisibleForUser" class="f-align-start-justify-end mt-12">
                <CRButton pattern="thin" @click="isMoreSettingsShown = !isMoreSettingsShown">
                    {{ $t('buttons.moreSettings') }}
                </CRButton>
            </div>
        </div>

        <!-- Cancellation request -->
        <div v-if="isCancellationRequestBtnVisibleForUser && isMoreSettingsShown" class="inner-item">
            <CRButton
                class="w-full mb-12"
                color="danger"
                @click="updateIndividualOrder(IndividualOrderStatusEnum.CANCELLATION_REQUEST)"
            >
                {{ $t('kitManagement.cancellationRequest') }}
            </CRButton>

            <p class="subtitle">
                {{ $t('kitManagement.cancellationRequestSubtitle') }}
            </p>
        </div>
        <div v-if="isAdmin && isCancellationRequestStatus" class="inner-item">
            <CRButton
                class="w-full mb-12"
                color="danger"
                @click="updateIndividualOrder(IndividualOrderStatusEnum.DECLINED)"
            >
                {{ $t('buttons.cancel') }}
            </CRButton>
            <div class="f-align-start-justify-end">
                <CRButton pattern="thin" @click="updateIndividualOrder(IndividualOrderStatusEnum.PAID)">
                    {{ $t('buttons.continueOrder') }}
                </CRButton>
            </div>
        </div>

        <div v-if="isAdmin && isPaidStatus" class="inner-item">
            <CRButton
                class="w-full mb-12"
                color="danger"
                @click="updateIndividualOrder(IndividualOrderStatusEnum.DECLINED)"
            >
                {{ $t('buttons.cancel') }}
            </CRButton>
        </div>

        <!-- Comment section -->
        <div v-if="isAdmin && !isCompletedStatus" class="inner-item">
            <slot name="comment-section" />
        </div>
    </CheckOutCard>
</template>

<script>
    import CheckOutCard from '@/components/OrderManagement/CheckOutCard.vue';
    import OMTitleSection from '@/components/OrderManagement/OMTitleSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { CheckOutStatusEnum, IndividualOrderStatusEnum, PaymentTypeEnum } from '@/utils/enums';
    import { mapGetters } from 'vuex';
    import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';

    export default {
        name: 'IndividualOrderKitCard',
        components: {
            CheckOutCard,
            CRButton,
            OMTitleSection,
        },
        props: {
            order: {
                type: Object,
                default: () => {},
            },
            checkOut: {
                type: Object,
                default: () => {},
            },
            kitOrderSettings: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                CheckOutStatusEnum,
                IndividualOrderStatusEnum,
                getLabelForDeliveryTime,

                isMoreSettingsShown: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isAdmin']),

            isCancellationRequestBtnVisibleForUser() {
                return !this.isAdmin && this.isPaidStatus && !this.kitOrderSettings?.isUserCancellationDisabled;
            },

            isChangeOrderBtnVisible() {
                return (
                    !this.isAdmin &&
                    !this.isCompletedStatus &&
                    !this.isCancellationRequestStatus &&
                    !this.isDeclinedStatus &&
                    !this.isNotPaidStatus &&
                    !this.kitOrderSettings?.isUserChangesDisabled
                );
            },

            isOrderRequestStatus() {
                return this.checkOut.status === CheckOutStatusEnum.ORDER_REQUEST;
            },

            isOrderApprovedStatus() {
                return this.checkOut.status === CheckOutStatusEnum.ORDER_APPROVED;
            },

            isWaitingPaymentStatus() {
                return this.checkOut.status === CheckOutStatusEnum.WAITING_PAYMENT;
            },

            isDeclinedStatus() {
                return (
                    this.checkOut.status === CheckOutStatusEnum.DECLINED &&
                    this.order.status === IndividualOrderStatusEnum.DECLINED
                );
            },

            isWaitingAccountPayment() {
                return (
                    this.isWaitingPaymentStatus &&
                    this.checkOut.payment.paymentableType === PaymentTypeEnum.BANK_ACCOUNT
                );
            },

            isCompletedStatus() {
                return (
                    this.checkOut.status === CheckOutStatusEnum.COMPLETED &&
                    this.order.status === IndividualOrderStatusEnum.COMPLETED
                );
            },

            isNotPaidStatus() {
                return (
                    this.checkOut.status === CheckOutStatusEnum.NOT_PAID &&
                    this.order.status === IndividualOrderStatusEnum.NOT_PAID
                );
            },

            isPaidStatus() {
                return (
                    this.checkOut.status === CheckOutStatusEnum.PAID &&
                    this.order.status === IndividualOrderStatusEnum.PAID
                );
            },

            isCancellationRequestStatus() {
                return (
                    this.checkOut.status === CheckOutStatusEnum.PAID &&
                    this.order.status === IndividualOrderStatusEnum.CANCELLATION_REQUEST
                );
            },

            deliveryStartDate() {
                return this.order.deliveryStartDate;
            },

            deliveryEndDate() {
                return this.order.deliveryEndDate;
            },

            isTimeApprovalSectionVisible() {
                return (
                    this.isOrderApprovedStatus ||
                    (this.isWaitingPaymentStatus &&
                        this.checkOut.payment.paymentableType === PaymentTypeEnum.BANK_ACCOUNT)
                );
            },
        },

        methods: {
            openConfirmationModal(status) {
                this.$emit('openConfirmationModal', status);
            },

            updateIndividualOrder(status) {
                this.$emit('updateIndividualOrder', { status, order: this.order });
            },

            makePayment() {
                this.$router.push({
                    name: 'individualOrderPayment',
                    params: { check_out_id: this.checkOut.id },
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        .inner-item {
            padding: 10px 0;

            border-bottom: 1px solid $grey-line;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        .payment-text {
            margin-top: 4px;
            font-style: italic;
            font-size: $font-14;
        }

        .price__wrapper {
            @include row-align-start-justify-between;
            flex-wrap: wrap;

            .payment-text__wrapper {
                max-width: 49%;
                margin-right: 1%;
            }

            .price-item {
                width: 50%;

                h6 {
                    text-align: end;
                }
            }

            .payment-btn {
                width: 100%;

                margin-top: 12px;
            }
        }

        .title {
            cursor: pointer;
            text-decoration: underline;
        }

        .status__wrapper {
            margin-top: 12px;

            h1 {
                font-size: $font-30;
                line-height: 120%;
            }

            .danger {
                color: $danger;
                max-width: 80%;
            }
        }

        .subtitle {
            font-size: $font-14;
            font-style: italic;
        }

        .text-danger {
            font-size: $font-14;
            color: $danger;
            font-style: italic;
        }

        .order-request-section {
            margin-top: 25px;

            .btn {
                width: 100%;
            }

            .save-btn {
                margin: 12px 0;
            }
        }
    }
</style>
